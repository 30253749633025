import { FC, ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { PersonalData } from '../PersonalDataContainer/PersonalData';
import { selectTab, selectTabIndex } from '../../appSlice';
import { Accounts } from '../AccountsContainer/Accounts';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import { LegalInfo } from '../LegalInfoContainer/LegalInfo';
import { LoginAndSecurity } from '../LoginAndSecurity/LoginAndSecurity';
import { UserInNavbar } from './UserInNavbar';
import { getUserProfileId } from '../../../../configuration/login/loginSlice';
import { useFetchAccountsQuery, useFetchUserQuery } from '../../services/usersApi';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { settingsTab, SettingsTab } from '../../../../configuration/types';

interface Tab {
    title: ReactElement;
    subTitle: ReactNode;
    icon: string;
    content: ReactElement;
    label: SettingsTab;
}

interface BodyProps {
    tabs: Tab[];
    setTabIndex: (n: SettingsTab) => void;
    tabIndex: SettingsTab;
}

const UserSettingsBody = (props: BodyProps) => {
    const { tabs, setTabIndex, tabIndex } = props;

    const renderTabLink = (tab: Tab, index: number, setTabIndex: (n: SettingsTab) => void, tabIndex: SettingsTab) => {
        const activeClass = tab.label === tabIndex ? 'menu-item active' : 'menu-item';

        return (
            <li key={`link${index}`} className={activeClass}>
                <a onClick={() => setTabIndex(tab.label)} href={'/#'}>
                    <div className={'flex-1-1 display-flex align-items-center gap-10 padding-y-2'}>
                        <div className={'position-relative'}>
                            <span className={`text-size-h4 text-color-primary rioglyph ${tab.icon}`} />
                        </div>
                        <div className={'line-height-125rel'}>
                            <div className={'text-size-16'}>{tab.title}</div>
                            <div className={'text-size-12 text-color-dark'}>{tab.subTitle}</div>
                        </div>
                    </div>
                </a>
            </li>
        );
    };

    const renderTabLinks = (tabs: Tab[], setTabIndex: (n: SettingsTab) => void, tabIndex: SettingsTab) => {
        return (
            <div className={'margin-bottom-10'}>
                <UserInNavbar />
                <ul className={'nav nav-pills nav-pills-stacked-decent nav-stacked'}>
                    {tabs.map((tab, index: number) => renderTabLink(tab, index, setTabIndex, tabIndex))}
                </ul>
            </div>
        );
    };

    const tab = tabs.find((tab) => tab.label === tabIndex);

    return (
        <div className={'min-height-350'}>
            <div className={'UserSettingsBody container-fluid row padding-0'}>
                <div className={'display-flex flex-wrap justify-content-center gap-20'}>
                    <div className={'flex-1-1-0 navigation min-width-300 max-width-300'}>
                        {renderTabLinks(tabs, setTabIndex, tabIndex)}
                    </div>
                    {tab && <div className={'flex-1-1 content'}>{tab.content}</div>}
                </div>
            </div>
        </div>
    );
};

export const UserSettingsDialog: FC = () => {
    const userProfileId = useAppSelector(getUserProfileId);

    const dispatch = useAppDispatch();

    const { isLoading } = useFetchUserQuery(userProfileId!);
    const { data: accounts, isLoading: isFetchAccountsLoading } = useFetchAccountsQuery(userProfileId!);

    const tabIndex = useAppSelector(selectTabIndex);

    const setTabIndex = (tabNumber: SettingsTab) => {
        dispatch(selectTab(tabNumber));
    };

    const getTabItem = (
        headlineKey: string,
        subTitle: { key: string | undefined; isLoading?: boolean },
        icon: string,
        content: ReactNode,
        label: SettingsTab
    ) => ({
        title: <FormattedMessage id={headlineKey} />,
        subTitle: subTitle.isLoading ? null : subTitle.key ? <FormattedMessage id={subTitle.key} /> : null,
        icon: icon,
        content: <div>{content}</div>,
        label: label,
    });

    const userMenuTabs: Tab[] = [
        getTabItem(
            'intl-msg:settingsDialog.tab.personalData.headline',
            { key: 'intl-msg:settingsDialog.tab.personalData.subTitle' },
            'rioglyph-user',
            <PersonalData />,
            settingsTab.PERSONAL_INFO
        ),
        getTabItem(
            'intl-msg:settingsDialog.tab.loginAndSecurity.headline',
            { key: 'intl-msg:settingsDialog.tab.loginAndSecurity.subTitle' },
            'rioglyph-eye-close',
            <LoginAndSecurity />,
            settingsTab.LOGIN_AND_SECURITY
        ),
        getTabItem(
            'intl-msg:settingsDialog.tab.accounts.headline',
            { key: 'intl-msg:settingsDialog.tab.accounts.subTitle' },
            'rioglyph-truck-unit',
            <Accounts />,
            settingsTab.SWITCH_FLEET
        ),
        getTabItem(
            'intl-msg:settingsDialog.tab.legalInfo.headline',
            { key: 'intl-msg:settingsDialog.tab.legalInfo.subTitle' },
            'rioglyph-info-sign',
            <LegalInfo />,
            settingsTab.LEGAL
        ),
    ];

    if (accounts && accounts.length === 1) {
        userMenuTabs.splice(2, 1);
    }

    return isLoading || isFetchAccountsLoading ? (
        <div className={'min-height-350 display-flex'}>
            <div className={'container-fluid row padding-0'}>
                <Spinner isDoubleSized />
            </div>
        </div>
    ) : (
        <UserSettingsBody tabIndex={tabIndex} tabs={userMenuTabs} setTabIndex={setTabIndex} />
    );
};
