import { PropsWithChildren } from 'react';

const PanelWrapper = (props: PropsWithChildren) => {
    return (
        <div className="container-fluid fluid-default margin-top-50">
            <div className="panel panel-default">
                <div className="panel-body padding-y-50 display-flex flex-column">{props.children}</div>
            </div>
        </div>
    );
};

export const Wrapper = (props: PropsWithChildren) => {
    const inIframe = window.self !== window.top;

    return inIframe ? props.children : <PanelWrapper>{props.children}</PanelWrapper>;
};
