import { useAppSelector } from '../../../../configuration/setup/hooks';
import { getLogisticsTenant, getUserPool, getUserProfile } from '../../../../configuration/login/loginSlice';
import { FormattedMessage } from 'react-intl';

const MIN_HEIGHT_MATCHING_DIALOG = '321px';

export const MinimalUserInfoDialogContent = () => {
    const userProfile = useAppSelector(getUserProfile);
    const userPool = useAppSelector(getUserPool);
    const logisticsTenant = useAppSelector(getLogisticsTenant);
    return userProfile === null ? null : (
        <div
            className={'display-flex flex-column justify-content-center'}
            style={{ minHeight: MIN_HEIGHT_MATCHING_DIALOG }}
        >
            <div className={'display-flex justify-content-center'}>
                <fieldset>
                    {userProfile.name && <FormGroup label={'name'} value={userProfile.name} />}
                    {!userProfile.name && userProfile.givenName && (
                        <FormGroup label={'first_name'} value={userProfile.givenName} />
                    )}
                    {!userProfile.name && userProfile.familyName && (
                        <FormGroup label={'last_name'} value={userProfile.familyName} />
                    )}
                    {userProfile.username && <FormGroup label={'username'} value={userProfile.username} />}
                    {userPool && <FormGroup label={'user_pool'} value={userPool} />}
                    {logisticsTenant && <FormGroup label={'logistics_tenant'} value={logisticsTenant} />}
                </fieldset>
            </div>
        </div>
    );
};

const FormGroup = ({ label, value }: { label: string; value: string }) => (
    <div className="form-group width-400">
        <label htmlFor={`input-${label}`}>
            <FormattedMessage id={`intl-msg:minimalSettingsDialog.label.${label}`} />
        </label>
        <input id={`input-${label}`} className="form-control pointer-events-none" type="text" value={value} readOnly />
    </div>
);
