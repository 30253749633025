import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../configuration/setup/store';
import { AppState, SettingsTab, settingsTab } from '../../configuration/types';

export const initialState: AppState = {
    sessionExpiredAcknowledged: false,
    showSettingsDialog: false,
    pending: false,
    selectedTab: settingsTab.PERSONAL_INFO,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        hideSessionExpiredDialog: (state) => {
            state.sessionExpiredAcknowledged = true;
        },
        showSettingsDialog: (state, action: PayloadAction<SettingsTab | undefined>) => {
            state.showSettingsDialog = true;
            if (action.payload) {
                state.selectedTab = action.payload;
            } else {
                state.selectedTab = settingsTab.PERSONAL_INFO;
            }
        },
        hideSettingsDialog: (state) => {
            state.showSettingsDialog = false;
        },
        selectTab: (state, action: PayloadAction<SettingsTab>) => {
            state.selectedTab = action.payload;
        },
    },
});

export const appReducer = appSlice.reducer;
export const { hideSessionExpiredDialog, showSettingsDialog, hideSettingsDialog, selectTab } = appSlice.actions;

// ------------------------------
// Selectors

export const getSessionExpiredAcknowledged = (state: RootState) => state.app.sessionExpiredAcknowledged;

export const selectShowSettingsDialog = (state: RootState) => state.app.showSettingsDialog;

export const selectTabIndex = (state: RootState) => state.app.selectedTab;

export const isMaintenanceMode = (state: RootState) => false;
