import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import ApplicationLayoutBodyBanner from '@rio-cloud/rio-uikit/ApplicationLayoutBodyBanner';
import { UserSettingsDialog } from './UserSettingsDialog';
import { IFrameWindow } from '../../../../configuration/types';
import { hideSettingsDialog, isMaintenanceMode } from '../../appSlice';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import { config } from '../../../../config';
import { pageLogout, pageRedirect, sendMessageToIFrameWrapper } from '../../../common/iFrameHelper';
import { redirectToLogout } from '../../../../configuration/login/logout';
import { useCloseDialogOnEscape } from '../../common/closeDialogOnEscape';
import { getIsRioProdUserPool } from '../../../../configuration/login/loginSlice';
import { MinimalUserInfoDialogContent } from './MinimalUserInfoDialogContent';
import { Wrapper } from './Wrapper';

export const UserSettingsDialogWrapper: React.FC = () => {
    const isInMaintenanceMode = useAppSelector(isMaintenanceMode);
    const isRioProdUserPool = useAppSelector(getIsRioProdUserPool);

    const dispatch = useAppDispatch();

    // Causes high number of logs in Sentry. Since we have a quote there this is disabled for now.
    // useEffect(() => {
    //     const [, query] = window.location.hash.split('?');
    //     const params = Object.fromEntries(new URLSearchParams(query));
    //     logMessageToSentry('User menu dialog opened', {
    //         'component.version': params['component_version'] ? params['component_version'] : 'unknown',
    //     });
    // }, []);

    const closeDialog = () => {
        if ('parentIFrame' in window) {
            (window as IFrameWindow).parentIFrame.sendMessage({
                type: 'IFRAME_CLOSE_DIALOG',
            });
        } else {
            dispatch(hideSettingsDialog());
        }
    };

    useCloseDialogOnEscape({ isShowDialog: true, closeDialog: closeDialog });

    const banner = isInMaintenanceMode ? (
        <ApplicationLayoutBodyBanner className={'text-center'}>
            <FormattedMessage id={'intl-msg:maintenanceMode'} />
        </ApplicationLayoutBodyBanner>
    ) : null;

    const broadcastLogout = () => {
        if ('parentIFrame' in window) {
            const logoutURI = config.backend.LOGOUT_URI
                ? config.backend.LOGOUT_URI
                : 'https://auth.iam.rio.cloud/logout';
            sendMessageToIFrameWrapper(pageRedirect(logoutURI));
            sendMessageToIFrameWrapper(pageLogout()); // compatibility with old component
        } else {
            redirectToLogout();
        }
    };

    return (
        <Wrapper>
            <div className="modal-content">
                {banner}
                {<ModalHeader closeDialog={closeDialog} />}
                <div className="modal-body padding-y-15 padding-x-20">
                    {isRioProdUserPool ? <UserSettingsDialog /> : <MinimalUserInfoDialogContent />}
                </div>
                <div className="modal-footer" aria-label="dialog footer">
                    <button
                        type="button"
                        className="btn btn-muted padding-left-0 pull-left margin-top--5 margin-bottom-15"
                        onClick={broadcastLogout}
                    >
                        <span className="rioglyph rioglyph-logout" />
                        <FormattedMessage id={'intl-msg:popover.logout'} />
                    </button>
                </div>
            </div>
        </Wrapper>
    );
};

const ModalHeader = ({ closeDialog }: { closeDialog: () => void }) => {
    const isRioProdUserPool = useAppSelector(getIsRioProdUserPool);
    const inIframe = window.self !== window.top;

    return (
        <div className="modal-header padding-top-10 margin-right--2">
            <div className="modal-header-text">
                <div className="modal-header-title">
                    {isRioProdUserPool ? (
                        <FormattedMessage id={'intl-msg:popover.link.profile'} />
                    ) : (
                        <FormattedMessage id={'intl-msg:minimalSettingsDialog.header'} />
                    )}
                </div>
            </div>
            {inIframe && (
                <div className="modal-header-buttons">
                    <button
                        type="button"
                        className="btn btn-icon-only btn-muted modal-header-close close"
                        onClick={closeDialog}
                    >
                        <span className="rioglyph rioglyph-remove" aria-label={'close dialog icon'} />
                    </button>
                </div>
            )}
        </div>
    );
};
