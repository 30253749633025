import * as React from 'react';

export const settingsTab = {
    PERSONAL_INFO: 'personalInfo',
    LOGIN_AND_SECURITY: 'loginAndSecurity',
    SWITCH_FLEET: 'switchFleet',
    LEGAL: 'legal',
} as const;

export type SettingsTab = (typeof settingsTab)[keyof typeof settingsTab];

export interface AppState {
    sessionExpiredAcknowledged: boolean;
    showSettingsDialog: boolean;
    pending: boolean;
    selectedTab: SettingsTab;
}

export interface LanguageDropdownItem {
    id: string;
    label: React.ReactNode;
    selected: boolean;
}

export type SupportedLocales = string[];

export interface UserInfo {
    firstName: string;
    lastName: string;
    username: string;
}

export interface IFrameWindow extends Window {
    parentIFrame?: any;
    iFrameResizer?: any;
}

export type AccessToken = string | undefined | null;

export type IdToken = {
    sub: string;
    azp: string;
    account: string;
    given_name: string;
    family_name: string;
    name: string;
    locale: string;
    email: string;
    username: string;
    tenant: string;
    rawValue: string;
};

export interface OAuthConfig {
    onSessionExpired: Function;
    onSessionRenewed: Function;
}

export interface UserProfile {
    sub?: string;
    azp?: string;
    account?: string;
    givenName?: string;
    familyName?: string;
    name?: string;
    locale?: string;
    email?: string;
    logisticsTenant?: string;
}

interface UserProfileType {
    sub: string;
    email?: string;
    name?: string;
    givenName?: string;
    familyName?: string;
    locale: string;
    username: string;
    logisticsTenant?: string;
}

export interface LoginState {
    hasUserSessionEverExpired: boolean;
    userProfile: UserProfileType | null;
    userSessionExpired: boolean;
}

export interface ResponseError extends Error {
    status?: number;
    body?: string;
}
